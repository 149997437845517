import React, {useEffect} from 'react';
import shallow from 'zustand/shallow';
import {Link} from 'gatsby';
import Layout from '../components/Layout';
import {usePhotoStore} from '../store';

const RenderProjects = ({projectsInfo}) => {
  const {setCurrentProject} = usePhotoStore(
    (state) => ({
      setCurrentProject: state.setCurrentProject,
    }),
    shallow
  );

  return projectsInfo.map((projectItem) => (
    <article
      key={projectItem.projectName}
      style={{backgroundImage: `url('${projectItem.firstProjectPhoto}')`}}>
      <header className="major">
        <h3>{projectItem.projectDescription}</h3>
        <p>{projectItem.projectTitle}</p>
      </header>
      <Link
        to={`/project?=${projectItem.projectName}`}
        tabIndex={0}
        role="button"
        onKeyPress={() => setCurrentProject(projectItem)}
        onClick={() => setCurrentProject(projectItem)}
        className="link primary"
      />
    </article>
  ));
};

const HomeIndex = () => {
  const {getProjectsInfo, projectsInfo} = usePhotoStore(
    (state) => ({
      getProjectsInfo: state.getProjectsInfo,
      projectsInfo: state.projectsInfo,
    }),
    shallow
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getProjectsInfo();
    }

    return () => {
      mounted = false;
    };
  }, [getProjectsInfo]);

  return (
    projectsInfo && (
      <Layout>
        <div id="main">
          <section id="one" className="tiles">
            <RenderProjects projectsInfo={projectsInfo} />
          </section>
        </div>
      </Layout>
    )
  );
};

export default HomeIndex;
